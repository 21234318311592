.chartTitle{
    width:300px;
    height:30px;
    line-height: 30px;
    background: #1f2a2c;
    font-size: 18px;
    color: #FFC141;
    text-align: left;
    font-weight: bold;
}
.chartCon{
    background:#1f2a2c;
    color:white;
    padding:20px 20px;
}
.statisticTable tr,.statisticTable td{
    padding:20px 20px;
    color:white;
}
