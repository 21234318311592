.main{
    width: 100%;
    height: 100%;
    background-color:#222222;
    min-width: 1200px;
    position: relative;
}

.header{
    width: 100%;
    height: 80px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
}

.content{
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
    flex-wrap: nowrap;
    position: relative;
}

.contentChild{
    /* flex-basis: 32%; */
    margin: 0 10px;
}

.leftContent{
    height: 100%;
    position: relative;
    /* flex-basis: 320px;
    flex-grow: 1.5;
    flex-shrink: 3;
    max-width: 32%; */
    min-width: 320px;
    width: 29.167%;
}

.midContent{
    position: relative;
    margin: 0;
    flex-basis: 33%;
    flex-grow: 1;
    min-width: 400px;
    height: calc(100% - 20px);
    top:15px;
    /* padding-bottom: 50px; */
}

.rightContent{
    height: 100%;
    position: relative;
    /* flex-basis: 320px;
    flex-grow: 1.5; */
    min-width: 320px;
    width: 29.167%;
}

.leftTop{
    height: 75%;
    display: flex;
}

.cuCarTab{
    width: 48%;
    margin:0 5px;
    /* box-shadow:59px 16px 57px -44px #222222;
    position: relative;
    z-index: 18; */
    position: relative;
    z-index: 16;
    box-shadow: 24px 24px 24px rgba(0,0,0,0.5);
    background-color: #212121;
}

.bacCarTab{
    width: 48%;
    margin:0 5px;
    background-color: #212121;
    position: relative;
    z-index: 14;
}

.carDetail{
    flex-basis: 33%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}

.carDetailCon{
    display: flex;
    flex-direction: column;
    height: 27%;
}

.detailTab{
    /* background:rgba(255,255,255,0.10); */
    padding: 10px 14px;
    text-align: center;
    color: #FFFFFF;
    /* border: 2px solid rgba(255,255,255,0.40); */
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    height: 50px;
    flex-basis: auto;
}

.detailContent1{
    background:rgba(3,221,253,0.5);
    padding: 12px 14px;
    display: flex;
    justify-content: flex-start;
}

.detailContent2{
    background:rgba(3,221,253,0.3);
    padding: 12px 14px;
    display: flex;
    justify-content: flex-start;
}

.detailTitle{
    font-weight: bold;
    color: #03DDFD;
    white-space: nowrap;
    /* overflow: hidden;
    text-overflow: ellipsis; */
    margin-right: 5px;
}

.detailText{
    font-weight: bold;
    color: #FFFFFF;
 /*   white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

.detailTextCon{
    overflow: auto;
    height: 50%;
    padding: 12px 14px;
    font-size: 14px;
}

.gaugeCon{
    height: 25%;
    border-bottom:2px solid #131313;
}

.mapCon{
    width: 100%;
    height: 75%;
    position: relative;
    /* padding: 10px;
    border: 1px solid rgba(15,247,255,0.30);
    box-shadow: inset 0 0 30px 0 rgba(15,247,255,0.20);
    top:-10px; */
}

.mapCon::before{
    content: '';
    position: absolute;
    width: 32px;
    height: 35px;
    top: -18px;
    left: -17px;
    z-index: 12;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background: #222222;
    border-bottom: rgba(15,247,255,1) 1px solid;
    box-shadow: 2px 12px 4px -5px rgba(15,247,255,0.2);
}

.mapCon::after{
    content:'';
    position: absolute;
    width: 27px;
    height: 16px;
    transform: rotate(-45deg);
    right:-9.5px;
    bottom: -5.5px;
    background: #222222;
    z-index: 12;
    border-top: rgba(15,247,255,1) 1px solid;
}


.OutervideoCon{
    width: 100%;
    height: 25%;
    background-color: #212121;
}

.videoHeader{
    height: 30px;
    width: 100%;
    display: flex;
}

.videoHeaderText{
    width: 50%;
    text-align: center;
    color:#FFC141;
    line-height: 30px;
}

.outerVideoContent{
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
}

.singleVideo{
    width: calc(50% - 5px);
}

.rightTabs{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.rightDetailTab{
    width: 49%;
    /* background:rgba(255,255,255,0.10); */
    padding: 10px 14px;
    text-align: center;
    color: #FFFFFF;
    /* border: 2px solid rgba(255,255,255,0.40); */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.rightTwoCon{
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
}

.testRulesCon{
    width: 100%;
    flex-basis: 45%;
    height: 45%;
}

.RollingeventsCon{
    width: 100%;
    flex-basis: 55%;
    overflow: hidden;
    position: relative;
    z-index: 3;
    padding-top: 10px;
}

.yellowTitle{
    width: 100%;
    height: 56px;
    background-image: linear-gradient(-180deg, #FFE296 0%, #FFBA31 47%, #FFE296 100%);
    box-shadow: 0 10px 20px 1px rgba(27,29,34,0.30);
    font-size: 18px;
    padding: 14px 0 14px 30px;
    font-weight: bold;
}

.rulesLeft{
    width: 36%;
    height: 100%;
    position: relative;
    z-index: 1;
    box-shadow: 24px 24px 24px rgba(34,34,34,0.5)
}

.rulesRight{
    width: 64%;
    height: 100%;
}

.eventDetailCon{
    width: 100%;
    height: calc(100% - 56px);
    overflow: hidden;
}

.listHeader{
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: space-between;
}

.eventListText{
    color: #FFC141;
    padding-top: 2px;
    padding-bottom: 2px;
}

.evnetHeaderName{
    width: 24%;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.evnetHeaderTime{
    width: 20%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}

.evnetHeaderDetail{
    width: 80%;
    text-align: center;
    /*overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;*/
}

.eventListCon{
    width: 100%;
    height: calc(100% - 24px);
    overflow-y: auto;
}

.detailConDark{
    width: 100%;
    min-height: 50px;
    padding:10px;
    display: flex;
    justify-content: space-between;
    background:rgba(3,221,253,0.05);
    line-height: 20px;
}

.detailConLight{
    width: 100%;
    min-height: 50px;
    padding:10px;
    margin:5px 0px;
    display: flex;
    justify-content: space-between;
    background:rgba(3,221,253,0.4);
    line-height: 20px;
}

.eventTimeText{
    color:rgba(255,255,255,0.5)
}

/*.videoWrapCon{
    max-width:480px;
    max-height:337px;
!*    min-width:150px;
    min-height:89px;*!
    background:rgba(255,255,255,1);
    position:absolute;
    z-index:100;
    padding:10px 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}*/
.closeVideoIcon{
    float:right;
    margin-top:-5px;
    margin-bottom:5px;
    cursor:pointer;
}
.videoCon{
    /*max-width:510px;
    max-height:300px;*/
    width:100%;
    height:100%;
    position:relative;
    clear:both;
}
.spinCon{
    width:100%;
    height:97%;
}

.spinCon {
    width:100%;
    height:97%;
}

.leftBottom{
    height: 25%;
    position: relative;
    z-index: 20;
}

.lightCon{
    width: 100%;
    height: calc(100% - 56px);
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    background: #212121;
}

.lightPane{
    width: 25%;
    height: 100%;
    max-width: 80px;
}

.lightNumber{
    font-size: 20px;
    font-weight: bold;
    padding-left: 28px;
    padding-top: 5px;
}

.redNumber{
    color:#FF0000;
}

.yellowNumber{
    color:#FFEC00;
}

.greenNumber{
    color: #00FF12
}

#timeAlterChart{
    width:100%;
    height: 100%;
}

.noWrapText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tabText{
    width: 100%;
    display: inline-block;
}

.canvasWrapper{
    width:100%;
    height:100%;
    position:relative;
}

.accBarCanvas{
    position: absolute;
    bottom: 5%;
    right: 5%;
}

.canvasElement{
    position:absolute;
    /* left:0; */
    top:0;
}

.signalImgWrapper{
    width:100%;
    height:0;
    padding-bottom:100%;
    min-width: 60px;
    max-width: 80px;
}

.signalImg{
    width: 100%;
}


@media screen and (min-width:1680px){
    .canvasTitle{
        position: absolute;
        color:#03DDFD;
        left: 0;
        bottom: 25%;
        width: 50px;
        height: 50px;
        background-color: rgba(3, 221, 253,0.15);
        text-align: center;
        line-height: 20px;
        font-weight: bold;
        padding: 5px;
    }
}

@media screen and (max-width:1680px){
    .canvasTitle{
        color:#03DDFD;
        text-align: left;
        font-weight: bold;
        padding: 2px;
    }
}

.canvasNormalTitle{
    position:absolute;
    left: 0;
    top:0;
    color:rgba(132, 246, 255,0.5);
    text-align: left;
    padding: 2px;
}

.speedHistoryChart{
    width:100%;
    height: 25%;
    border-bottom:2px solid #131313;
}

.graphCon{
    height: 73%;
}

@media screen and (min-width:1680px){
    .accTitle{
        position: absolute;
        color:#03DDFD;
        left: 0;
        bottom: 25%;
        width: 60px;
        height: 60px;
        background-color: rgba(3, 221, 253,0.15);
        text-align: center;
        line-height: 25px;
        font-weight: bold;
        padding: 5px;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width:1680px){
    .accTitle{
        color:#03DDFD;
        text-align: left;
        font-weight: bold;
        padding: 2px;
    }
}

.leftDetailTitle{
    font-weight: bold;
    color: #03DDFD;
    white-space: nowrap;
    margin-right: 5px;
    display: inline-block;
    width: 50%;
}

.leftDetailText{
    text-align: center;
    display: inline-block;
    width: 50%
}

.rightTria{
    width: 0;
    height: 0;
    border-width: 20px;
    border-style: solid;
    border-color: transparent #03DDFD transparent transparent;
    position: fixed;
    top:220px;
    right: 0;
    cursor: pointer;
    transition: right 0.3s ease-in-out
}

.projectPane{
    width: 30%;
    height: 500px;
    border: 1px solid rgba(15,247,255,0.30);
    box-shadow: inset 0 0 30px 0 rgba(15,247,255,0.20);
    background-color: #222222;
    position: fixed;
    top: 160px;
    right: 0;
    transition: width 0.3s ease-in-out;
    z-index: 999;
}

.projectPaneHeader{
    width: 100%;
    height: 50px;
    color: #FFCE00;
    display: flex;
}

.projectHeaderTitle{
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 50%;
    justify-content: center;
    background-color: rgba(3, 221, 253,0.05);
}

.projectHeaderSubTitle{
    font-size: 12px;
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: center;
}

.projectPaneContent{
    width: 100%;
    height: calc(100% - 50px);
}

.schemeHeader{
    width: 100%;
    height: 50px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.schemeName{
    color: #FFFFFF;
    font-weight: bold;
}

.schemeLeftCol{
    width: 46%;
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.schemeCircleUnselect{
    width: 12px;
    height: 12px;
    border: 2px solid #B7F6FF;
    border-radius: 6px;
    margin:0 5px 0 0;
}

.schemeCircleSelect{
    width: 11px;
    height: 11px;
    border-radius: 8px;
    margin:0 5px 0 0;
    background-color: #FFCE00;
}

.schemeMidRightCol{
    width: 27%;
    padding: 0 5px;
}

.schemeHeaderText{
    color: #95A0AA;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.projectTitle{
    height: 30px;
    color: #95A0AA;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.scenesConUnSelect{
    height: 50px;
    width: 100%;
    display: flex;
    background-color: rgba(3, 221, 253,0.1);
    align-items: center;
    margin-bottom: 10px;
    padding-left: 10px;
    cursor: pointer;
}

.scenesConUnSelect:hover{
    background-color: rgba(3, 221, 253,0.5);
}

.scenesConSelect{
    height: 50px;
    width: 100%;
    display: flex;
    background-color: rgba(3, 221, 253,0.5);
    align-items: center;
    margin-bottom: 10px;
    padding-left: 10px;
    cursor: pointer;
}

.schemeTitle{
    color: #FFCE00;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.schemeTimeUnSelect{
    color: #95A0AA;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.schemeTimeSelect{
    color: #FFFFFF;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.1);
    position: absolute;
    top:0;
    bottom: 0;
    z-index: 777;
}

.playBar{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    font-size: 12px;
    background: rgba(3, 221, 253,0.1);
    height: 50px;
    z-index: 2;
}

.playBtn{

    background-image: url("./assets/startBtn.png");
    background-repeat: no-repeat;
    background-color:transparent;
    border:0px;
    height:35px;
    padding-left:45px;
    color:white;
    cursor: pointer;
    outline: none;
    margin-top:1px;
    margin-left:10px;
    font-size:18px;
    font-weight: bold;
}
.endBtn{
    background-image: url("./assets/endBtn.png");
    background-repeat: no-repeat;
    background-color:transparent;
    border:0px;
    height:35px;
    padding-left:45px;
    color:white;
    cursor: pointer;
    outline: none;
    margin-top:1px;
    margin-left:10px;
    font-size:18px;
    font-weight: bold;
}
.schemeContent{
    height: calc(100% - 50px);
    overflow-y: auto;
}

.rightCorner{
    background-image: url('./assets/right_corner.png')  ; 
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.leftCorner{
    background-image: url('./assets/left_corner.png')  ; 
    background-size: 100% 100%;
    background-repeat: no-repeat;
}