.main{
    width: 100%;
    height: 100%;
    background-color:#222222;
}

.content{
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    padding-top: 15px;
}

.header{
    width: 100%;
    height: 80px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
}

.mapCon{
    width: 95%;
    height: calc(100% - 20px);
    margin:0 auto;
    /* border: 1px solid rgba(15,247,255,1); */
       /*  box-shadow: inset 0 0 30px 0 rgba(15,247,255,0.20); */
    position: relative;
    z-index: 99;
}

.shadowLine{
    box-shadow: inset 0 0 30px 0 rgba(15,247,255,1);
}

.topLine{
    width: calc(100% - 20px);
    height: 1px;
    position: absolute;
    top:-1px;
    right: 0;
    z-index: 9;
    background-color:rgba(15,247,255,0.50);
    box-shadow:  0px 6px 17px 2px rgba(15,247,255,0.4);
}

.bottomLine{
    width: calc(100% - 15px);
    height: 1px;
    position: absolute;
    bottom:-1px;
    left: -1px;
    z-index: 9;
    background-color:rgba(15,247,255,0.50);
    box-shadow:  0px -5px 20px 4px rgba(15,247,255,0.2);
}

.leftLine{
    width: 1px;
    height: calc(100% - 19px);
    position: absolute;
    bottom:0px;
    left: -1px;
    z-index: 9;
    background-color:rgba(15,247,255,0.50);
    box-shadow:  7px 9px 22px 5px rgba(15,247,255,0.2);
}

.rightLine{
    width: 1px;
    height: calc(100% - 17px);
    position: absolute;
    top:0;
    right: 0;
    z-index: 9;
    background-color:rgba(15,247,255,0.50);
    box-shadow:  -5px 0px 14px 6px rgba(15,247,255,0.2);
}

.mapCon::before{
    content: '';
    position: absolute;
    width: 32px;
    height: 35px;
    top: -19px;
    left: -17px;
    z-index: 12;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background: #222222;
    border-bottom: rgba(15,247,255,1) 1px solid;
    box-shadow: 2px 12px 4px -5px rgba(15,247,255,0.2);
}

.mapCon::after{
    content:'';
    position: absolute;
    width: 27px;
    height: 14px;
    transform: rotate(-45deg);
    right:-7.5px;
    bottom: -2.5px;
    background: #222222;
    z-index: 12;
    border-top: rgba(15,247,255,1) 1px solid;
}