.pageCon{
    padding:20px 70px;
}
.searchCon{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
    padding-top:31px;
    padding-left:40px;
    padding-bottom:31px;
    margin-bottom:18px;
}
.selectStyle{
    width: 170px !important;
    /*height:38px !important;*/
    margin-right:31px !important;
    display: inline-block;

}
.timeStyle{
    width: 200px !important;
    height:38px !important;
    margin-right:31px !important;
    display: inline-block;
}
.searchBtn{
    width:146px;
    margin-right:20px !important;

}
.resetBtn{
    width:90px;
    background-image: url("./img/reset.png") !important;
    background-repeat: no-repeat !important;
    background-position:10px center !important;
}


.dataCon{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
}
.operaCon{
    margin:28px 52px 18px 52px;
    font-size: 14px;
    color: #909e9c;
}
.showRecord{
    margin-right:14px !important;
}
.btnStyle{
    background-color:#2D9C3C !important;
    color:white!important;
    width:132px;
    height:38px !important;
    border-color: #2D9C3C !important;
    margin-left:19px;
}
.projectNum{
    margin-left:13px;
    margin-right:84px;
    color: #333333;
    font-weight: bold;
}
.dataTable :global .ant-table-thead > tr > th{
    background-color:white;
    border-top: 1px solid #e8e8e8;
    color: #909e9c;
    font-size:12px;
}
.dataTable :global .ant-table-tbody> tr > td{
    color: #9EA0A5 !important;
    padding-top:24px;
    padding-bottom:24px;
}
.operaIcon{
    width:20px;
    height:20px;
    cursor:pointer;
}
.aStyle{
    margin-left:5px;
    margin-right:5px;
}
.sceneFillCon{
    background-color:#fbfbfd;
    padding-top:33px;
    padding-bottom:13px;
    border-bottom: 1px solid #e8e8e8;
}
.sceneTitleCon{
    padding:10px 10px 0px 10px;
    border: 1px dashed #EAEDF3;
    border-radius: 4px;
}
.sceneTextArea{
    border:0px !important;
    background-color:#fbfbfd !important;
}

.scene :global .ant-tabs-bar{
    margin:0px;
}
.sceneCancelBtn{
     margin-top:20px;
 }
.sceneSubmitBtn{
    margin-top:20px;
    text-align: right;
}

.viewSceneItem{
    background: #FBFBFD;
    border: 1px solid #EAEDF3;
    border-radius: 4px;
    padding:10px 20px;
    margin-bottom:9px;
}
.viewSceneTitle{
    font-size: 12px;
    color: #9EA0A5;
    margin-left:19px;
}
.viewSceneData{
    font-size: 14px;
    color: #3E3F42;
    margin-left:18px;
}
.editSceneBtnCon{
    text-align: right;
}
.editSceneBtn{
    box-shadow: 0 1px 1px 0;
    background-color:#2D9C3C;
    margin-bottom:10px;
}
.imgLittle{
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    float: left;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
}
.imgLittle img{
    width:100%;
    height:100%;

}
.sceneName{
    margin-bottom:27px;
}
.sceneNameTitle{
    font-size: 12px;
    color: #9EA0A5;
    margin-right:6px;
}
.allSceneCon,.targetSceneCon{
    background: #FBFBFD;
    border: 1px solid #EAEDF3;
    border-radius: 4px;
    min-height:450px;
    max-height:450px;
    overflow-y: auto;
}
.totalTips{
    margin-left:24px;
    margin-top:10px;
}
.noSceneTip{
    margin:40px;
    display:inline-block;
}
.selectSceneTitle{
    margin-bottom:10px;
}
.pTxt{
    font-size: 16px;
    color: #3E3F42;
    font-weight: bold;
}
.sTxt{
    font-size: 14px;
    color: #000000;
    font-weight: normal;
}
.sceneCancelBtn{
    margin-top:20px;
}
.sceneSubmitBtn{
    margin-top:20px;
    text-align: right;
}
.btnStyle{
    background-color:#2D9C3C !important;
    color:white!important;
    width:132px;
    height:38px !important;
    border-color: #2D9C3C !important;
    margin-left:19px;
}
.editPlanBtn{
    box-shadow: 0 1px 1px 0;
    background-color:#2D9C3C;
    margin-bottom:10px;
}

.planItemCon{
    margin-top:22px;
    padding-bottom:29px;
    border-bottom: 1px solid #EAEDF3;
}
.planItemCon :global .ant-form-item {
    margin-bottom:0px !important;
}
.planItemCon :global .ant-form-item-label{
    text-align: left;
}
.planTitleCon{
    font-size: 16px;
    color: #3E3F42;
    font-weight: bold;
}
.planTitle{
    margin-left:9px
}
.cancelBindBtn{
    width:130px;
    background-image: url("./img/reset.png") !important;
    background-repeat: no-repeat !important;
    background-position:10px center !important;
}
.txtBold{
    font-weight: bold;
    margin-right:15px;
}
.viewColSpace{
    margin-top:10px;
    margin-bottom:10px;
}