.leftCon{
    height: calc(100%);
    width:50%;
    float:left;
    background:url("./img/1.mp4") no-repeat ;
    /* background-image: url("./img/leftBg.png");
    background-repeat: no-repeat; */
    background-size:cover;
}
.leftCon video{
    position: fixed; left: 0; bottom: 0;
    min-width:100%; min-height:100%; width: auto;height:
        auto; background-size: cover;
    object-fit: fill
}
.rightCon{
    height: calc(100%);
    width:50%;
    float:left;
    background-image: url("./img/rightBg.png");
    background-repeat: no-repeat;
    background-size:cover;
    position:relative;
}
.playBtn{
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
}
.formCon{
    width:410px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:white !important;
}
.formCon h1{
    color:white !important;
    font-size: 45px;
}
.notice{
    font-size:18px;
}
.formCon :global .ant-input{
    background: rgba(255,255,255,0.15);
    border: 2px solid rgba(255,255,255,0.32);
    border-radius: 31px;
    height:62px;
    color:white;
}
.subBtn{
    background: #FFFFFF;
    border-radius: 31px;
    height:62px;
    font-size: 20px;
    color: #1890ff;
    font-weight: bold;
}