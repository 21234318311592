.pageCon{
    padding:20px 70px;
}
.searchCon{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
    padding-top:31px;
    padding-left:40px;
    padding-bottom:31px;
    margin-bottom:18px;
}
.selectStyle{
    width: 170px !important;
    /*height:38px !important;*/
    margin-right:31px !important;
    display: inline-block;

}
.dataCon{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
}
.operaCon{
    margin:28px 52px 18px 52px;
    font-size: 14px;
    color: #909e9c;
}
.showRecord{
    margin-right:14px !important;
}
.btnStyle{
    background-color:#2D9C3C !important;
    color:white!important;
    width:132px;
    height:38px !important;
    border-color: #2D9C3C !important;
    margin-left:19px;
}
.projectNum{
    margin-left:13px;
    margin-right:84px;
    color: #333333;
    font-weight: bold;
}
.dataTable :global .ant-table-thead > tr > th{
    background-color:white;
    border-top: 1px solid #e8e8e8;
    color: #909e9c;
    font-size:12px;
}
.dataTable :global .ant-table-tbody> tr > td{
    color: #9EA0A5 !important;
    padding-top:24px;
    padding-bottom:24px;
}
.searchBtn{
    width:146px;
    margin-right:20px !important;

}
.resetBtn{
    width:90px;
    background-image: url("./img/reset.png") !important;
    background-repeat: no-repeat !important;
    background-position:10px center !important;
}
.operaIcon{
    width:20px;
    height:20px;
    cursor:pointer;
}







.reportBack{
    margin:5px 0px;
    cursor: pointer;
}
.reportLeft :global .ant-affix{
    left: unset !important;
}

.reportLeftCon{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
}
.reportLeftTitle{
    height:30px;
    font-size: 22px;
    color: #3E3F42;
    line-height: 28px;
}
.reportLeftTitle p{
    margin-left:20px;
    margin-top:34px;
}
.reportLeftItem{
    background: #FFFFFF;
    border-top: 1px solid #EAEDF3;
    border-bottom: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    line-height: 56px;
    margin-top:18px;
}
.reportLeftItem .title{
    width:40%;
    padding-left:21px;
    display: inline-block;
    color: #9EA0A5;
}
.reportLeftItem .content{
    width:50%;
    display: inline-block;
    color: #3E3F42;
    font-weight: bold;
}

.reportMiddleCon :global .ant-form-item{
    margin-bottom:0px;
}
.reportMiddleTitle{
    height:75px;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
    font-size: 22px;
    color: #3E3F42;
    line-height: 75px;

}
.reportMiddleTitle p{
    margin-left:54px;
}
.reportMiddleItem{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 4px;
    margin-top:24px;

}
.caseTitle{
    height:46px;
    line-height: 46px;
    color: #9EA0A5;
    text-align: center;
}
.caseTitle li{
    width:33.333%;
    font-size: 14px;
    float:left;
    list-style: none;
}
.caseItemTitle{
    margin:11px 0px 11px 20px;
    color: #9EA0A5;
}
.caseItemCon{
   /* height:56px;*/
    padding:9px 0px;
    display:flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    margin-top:15px;
}
.caseItemLeft{
    width:41%;
    float:left;
    padding-left:54px;
    font-size: 14px;
    color: #3E3F42;
    line-height:20px;
}
.caseItemMiddle{
    width:33%;
    float:left
}
.caseItemRight{
    width:25%;
    float:left
}
.pass{
    background: #34AA44;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    border:0px;
    padding:4px 25px;
    line-height: 30px;
    margin-right:10px;
}
.longPass{
    background: #34AA44;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    border:0px;
    padding:4px 50px;
    line-height: 30px;
    margin-right:10px;
}
.noPass{
    background: #FACF55;
    border-radius: 4px;
    font-size: 14px;
    color: #3E3F42;
    text-align: center;
    border:0px;
    padding:4px 20px;
    line-height: 30px;
}
.longNoPass{
    background: #FACF55;
    border-radius: 4px;
    font-size: 14px;
    color: #3E3F42;
    text-align: center;
    border:0px;
    padding:4px 50px;
    line-height: 30px;
}
.reportRightConUp{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
}

.reportRightConUpTitle{
    height:50px;
    background: #FFFFFF;
    font-size: 22px;
    color: #3E3F42;
    line-height: 50px;
    border-bottom: 1px solid #EAEDF3;
    margin-bottom:15px;

}
.reportRightConUpTitle p{
    margin-left:54px;


}
.infoCon{
    margin-left:20%;
    margin-right:20%;
    width:60%;
    margin-bottom:14px;
}



.reportRightConDown{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
    margin-top:22px;
}

.reportRightConDownTitle{
    height:50px;
    background: #FFFFFF;
    font-size: 22px;
    color: #3E3F42;
    line-height: 50px;
    border-bottom: 1px solid #EAEDF3;
    margin-bottom:25px;

}

.reportRightConDownTitle p{
    margin-left:54px;
}

.reportRightConDownContent{
    margin-left:20%;
    margin-right:20%;
    margin-bottom:10px;
    width:60%;
}

.previewTitle{
    height:28px;
    line-height: 28px;
    font-size:20px;
    font-weight: bold;
    margin-bottom:5px !important;
    margin-top:20px;
    font-variant: normal;
}
.previewResultTitle{
    height:28px;
    line-height: 28px;
    border-bottom:1px dashed #e8e8e8;
}

.previewCarTitle{
    margin-right:10px;
    margin-top:10px;
    margin-bottom:10px;
    display:inline-block;
    font-weight: bold;
}
.previewCarData{
    font-weight: normal;
}
.previewCarItemInfo{
    margin-right:15px;

    margin-top:10px;
    margin-bottom:10px;
    display:inline-block;
}
.previewIcon{
    margin-right:8px;
    color:#aaa;
}
.space{
    margin-top:10px;
    margin-bottom:10px;
    height:20px;
}

.mask{
    background: rgba(0,0,0,0.4) !important;
    z-index: 10;
    height: 100vh;
    position: fixed;
    top:0px;
    left:0px;
    width: 100vw;
}
.selectMask_box{
    background: rgba(0,0,0,0);
    transition: all .2s linear
}
.previewCon{
   position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background:#fff;

    opacity: 1;

}
.resultTable{
    width:95%;
    border:1px solid #eee
}
.resultTable tr,.resultTable td,.resultTable th{
    padding:10px 10px;
    border:1px solid #eee;
    text-align: center;
}

.shouwei{
    text-align:right;
    font-size:18px;
    margin-right:5%;
}
.shouweiSpace{
    margin-left:10px;
}