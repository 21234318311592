.pageCon{
    padding:20px 70px;
}
.searchCon{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
    padding-top:31px;
    padding-left:40px;
    margin-bottom:18px;
}
.selectStyle{
    width: 170px !important;
    /*height: 38px !important;*/
    margin-right:31px !important;

}
.searchBtn{
    width:146px;
    margin-right:20px !important;

}
.resetBtn{
    width:90px;
    background-image: url("./img/reset.png") !important;
    background-repeat: no-repeat !important;
    background-position:10px center !important;
}
.viewWay{
    margin-top:42px;
}
.viewTitle{
    margin-right:32px;
    font-size: 14px;
    color: #b6bfbe;
}
.listViewSelect{
    width:94px;
    height:38px;
    display:inline-block;
    background-image: url("./img/listSelect.png");
    background-repeat: no-repeat;
    background-position:left center;
    border-bottom:3px solid #35AC45;
    text-align: right;
    line-height: 38px;
    margin-right: 42px;
    font-size: 14px;
    color: #333333;
    padding-bottom:10px;
}
.mapViewSelect{
    width:94px;
    height:38px;
    display:inline-block;
    background-image: url("./img/mapSelect.png");
    background-repeat: no-repeat;
    background-position:left center;
    border-bottom:3px solid #35AC45;
    text-align: right;
    line-height: 38px;
    font-size: 14px;
    color: #333333;
    padding-bottom:10px;
}
.listViewDisabled{
    width:94px;
    height:38px;
    display:inline-block;
    background-image: url("./img/listDisabled.png");
    background-repeat: no-repeat;
    background-position:left center;
    text-align: right;
    line-height: 38px;
    margin-right: 42px;
    font-size: 14px;
    color: #b6bfbe;
    padding-bottom:10px;
}
.mapViewDisabled{
    width:94px;
    height:38px;
    display:inline-block;
    background-image: url("./img/mapDisabled.png");
    background-repeat: no-repeat;
    background-position:left center;
    border-bottom:2px;
    text-align: right;
    line-height: 38px;
    font-size: 14px;
    color: #b6bfbe;
    padding-bottom:10px;
}
.dataCon{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
}
.operaCon{
    margin:28px 52px 18px 52px;
    font-size: 14px;
    color: #909e9c;
}
.showRecord{
    margin-right:14px !important;
}
.btnStyle{
    background-color:#2D9C3C !important;
    color:white!important;
    width:132px;
    height:38px !important;
    border-color: #2D9C3C !important;
    margin-left:19px;
}
.projectNum{
    margin-left:13px;
    margin-right:84px;
    color: #333333;
    font-weight: bold;
}
.dataTable :global .ant-table-thead > tr > th{
    background-color:white;
    border-top: 1px solid #e8e8e8;
    color: #909e9c;
    font-size:12px;
}
.dataTable :global .ant-table-tbody> tr > td{
    color: #9EA0A5 !important;
    padding-top:24px;
    padding-bottom:24px;
}
.operaIcon{
    width:20px;
    height:20px;
    cursor:pointer;

}
.sceneFillCon{
    background-color:#fbfbfd;
    padding-top:33px;
    padding-bottom:13px;
    border-bottom: 1px solid #e8e8e8;
}
.sceneTitleCon{
    padding:10px 10px 0px 10px;
    border: 1px dashed #EAEDF3;
    border-radius: 4px;
}
.sceneTextArea{
    border:0px !important;
    background-color:#fbfbfd !important;
}

.scene :global .ant-tabs-bar{
    margin:0px;
}
.sceneCancelBtn{
     margin-top:20px;
 }
.sceneSubmitBtn{
    margin-top:20px;
    text-align: right;
}

.viewSceneItem{
    background: #FBFBFD;
    border: 1px solid #EAEDF3;
    border-radius: 4px;
    padding:10px 20px;
    margin-bottom:9px;
    display:flex;
    flex-direction: row;
}
.viewSceneTitle{
    font-size: 12px;
    color: #9EA0A5;
    margin-left:19px;
}
.viewSceneData{
    font-size: 14px;
    color: #3E3F42;
    margin-left:18px;
}
.editSceneBtnCon{
    text-align: right;
}
.editSceneBtn{
    box-shadow: 0 1px 1px 0;
    background-color:#2D9C3C;
    margin-bottom:10px;
}
.imgLittle{
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    float: left;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
}
.imgLittle img{
    width:100%;
    height:100%;
}
.autoHeight{
    height:100%;
    padding-bottom:3000px; margin-bottom:-3000px;
}
.mapHeight{
    height: calc(100% - 250px);
}