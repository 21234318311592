.logo{
    /*padding:11px 38px;*/
    font-size: 26px;
    color: #FFFFFF;
    text-align: center;
    background-color:#1a2325;
}
.longLogo{
    width:200px;
}
.shortLogo{
    width:65px;
}
.placeDiv{
    /*padding:11px 38px;*/
    font-size: 26px;
    color: #FFFFFF;
    text-align: center;
    background-color:#1f2a2c;
}