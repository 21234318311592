.main{
    width: 100%;
    height: 100%;
    min-width: 1103px;
}

.content{
    width: 100%;
    height: calc(100% - 60px);
    padding: 20px 70px;
}

.searchCard{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
    width: 100%;
    height: 100px;
    padding: 10px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    white-space: nowrap;
}

.searchInputGroup{
    display: flex;
    align-items: center;
}

.searchStatuSelect{
    display: flex;
    align-items: center;
}

.searchButtonGroup{
    display: flex;
    align-items: center;
}

.vutNumInput{
    background: rgba(255,255,255,0.28);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.08);
    border-radius: 4px;
}

.basicHover:hover{
    border-color: #38B148 !important;
}

.basicHover input:hover{
    border-color: #38B148 !important;
}

.basicHover input:focus{
    border-color: #38B148 !important;
    box-shadow: 0 0 0 2px rgba(56, 177, 72, 0.2) !important;
}

.buttonActive:not([disabled]):active{
    border-color: #38B148 !important;
}

.basicFocus:focus{
    border-color: rgb(56, 177, 72) !important;
    box-shadow: 0 0 0 2px rgba(56, 177, 72, 0.2) !important;
}

.searchStatuTitle{
    display: inline;
    color: #333333;
    font-size: 14px;
    margin-right: 20px;
}

.searchStatuText{
    display: inline;
    color:rgb(23, 53, 48,0.5);
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
}

.searchStatuTextSelect{
    border-bottom: 2px #38B148 solid;
}

.queryButton{
    background-image: linear-gradient(-1deg, #F6F7F9 2%, #FFFFFF 100%) !important;
    border: 1px solid #D8DCE6 !important;
    box-shadow: 0 1px 1px 0 rgba(22,29,37,0.05), inset 0 2px 0 0 rgba(255,255,255,0.05) !important;
    border-radius: 4px !important;
    color: #3E3F42 !important;
    width: 140px;
    margin-right: 20px;
}

.redoButton{
    border: 1px solid #E2E5ED;
    border-radius: 4px;
    background:transparent;
    color: #3E3F42 !important;
}

.icon{
    color: rgb(158, 160, 165, 0.5);
}

.tableCard{
    width: 100%;
    /* height: calc(100% - 120px); */
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
    padding: 10px 50px;
}

.tableCardHeader{
    width: 100%;
    height: 70px;
    display: flex;
    padding: 0 20px;
    align-items: center;
}

.tableStatus{
    display: flex;
    align-items: center;
}

.tableStatusTitle{
    color: #3E3F42;
    font-size: 16px;
    font-weight: bold;
    margin-right: 20px;
}

.tableStatusItem{
    line-height: 70px;
    margin: 0 20px;
}

.tableStatusItemTitle{
    color: rgb(23, 53, 48,0.5);
    margin-right: 10px;
}

.tableStatusItemText{
    color: #333333;
    font-weight: bold;
}

.tableCon{
    width: 100%;
    height: calc(100% - 70px);
}

.editIcon{
    cursor: pointer;
    font-size: 20px;
    color: #95A0AA;
}

.tableActionCon{
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalHeader{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.modalButton{
    background-image: linear-gradient(0deg, #34AA44 2%, #38B249 100%);
    border: 1px solid #2D9C3C;
    box-shadow: 0 1px 1px 0 rgba(19,31,21,0.10), inset 0 2px 0 0 rgba(255,255,255,0.06);
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 13px;
    cursor: pointer;
}

.editButton{
    width: 70px;
    height: 30px;
}

.modalFooter{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.modalCancelButton{
    background-image: linear-gradient(-1deg, #F6F7F9 2%, #FFFFFF 100%);
    border: 1px solid #D8DCE6;
    box-shadow: 0 1px 1px 0 rgba(22,29,37,0.05), inset 0 2px 0 0 rgba(255,255,255,0.05);
    border-radius: 4px;
    width:80px;
    height: 40px;
    cursor: pointer;
}